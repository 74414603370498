// Mood Board Color
export const BG_PRIMARY = 'rgba(245,244,242,255)';
export const BG_SECONDARY = 'rgba(120,116,113,1)';
export const BG_ALTERNATIVE = '#FFFFFF';
export const BG_OPACITY = 'rgba(31,31,31,1)';

// Text Color
export const TEXT_PRIMARY = 'rgba(61,70,70,1)';
export const TEXT_SECONDARY = 'rgba(67,67,72,1)';
export const TEXT_ALTERNATIVE = 'rgba(255,255,255,1)';

// Music & Navigation Color
export const NAVIGATION_COLOR = '#615d59'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
