/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `'Great Vibes', cursive;`,
  subHeading: `'Libre Caslon Text', serif;`,
  body: `'Old Standard TT', serif;`,
  body_lv2: `'Alegreya', serif;`,
  name: `'Great Vibes', cursive;`,
  and: `'Boheme Floral', cursive;`,
  fullName: `'Great Vibes', cursive;`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
    lineHeight: '100%',
    textAlign: 'center',
    color: 'secondaryColorText',
    // textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  subHeading: {
    fontFamily: 'subHeading',
    fontSize: 'subHeading',
    fontWeight: 'subHeading',
    fontStyle: 'var(--fontItalic-subHeading)',
    color: 'secondaryColorText',
    textAlign: 'center',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    color: 'mainColorText',
    textAlign: 'center',
  },
  body_lv2: {
    fontFamily: 'body_lv2',
    fontSize: 'body_lv2',
    fontWeight: 'body_lv2',
    fontStyle: 'var(--fontItalic-body_lv2)',
    color: 'mainColorText',
    textAlign: 'center',
  },
  nameProps: {
    fontFamily: 'name',
    fontSize: 'name',
    fontWeight: 'name',
    fontStyle: 'var(--fontItalic-name)',
    color: 'secondaryColorText',
    // textTransform: 'uppercase',
  },
  andProps: {
    fontFamily: 'and',
    fontSize: 'and',
    fontWeight: 'and',
    fontStyle: 'var(--fontItalic-and)',
    color: 'secondaryColorText',
    margin: '0 20px 0 14px',
  },
  fullNameProps: {
    fontFamily: 'fullName',
    fontSize: 'fullName',
    fontWeight: 'fullName',
    fontStyle: 'var(--fontItalic-fullName)',
    textAlign: 'center',
    color: 'secondaryColorText',
    letterSpacing: '1px',
    lineHeight: '1.3',
  },
};

export const BRIDE_AND_GROOM_TEXT_INVERT_PROPS = {
  name: {
    ...TEXT_PROPS.nameProps,
    color: 'alternativeColorText',
  },
  and: {
    ...TEXT_PROPS.andProps,
    color: 'alternativeColorText',
  },
};

export const DEFAULT_BUTTON_PROPS = {
  variant: 'solid',
  size: 'sm',
  padding: '8px 16px',
  borderRadius: '5px',
  fontWeight: '500',
  fontFamily: 'body',
  background: 'bgSecondary',
  color: 'alternativeColorText',
  fontSize: '16px',
  boxShadow: 'md',
};

export const DEFAULT_PROPS = {
  button_transparent: {
    _active: { borderColor: 'transparent' },
    _hover: { borderColor: 'transparent' },
    _focus: { borderColor: 'transparent' },
  },
  button: {
    ...DEFAULT_BUTTON_PROPS,
    _hover: { opacity: '.8' },
  },
  buttonSecondary: {
    ...DEFAULT_BUTTON_PROPS,
    _hover: { opacity: '.8' },
  },
};
