import { extendTheme } from '@chakra-ui/react';
import {
  BG_ALTERNATIVE,
  BG_PRIMARY,
  BG_SECONDARY,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_ALTERNATIVE,
  BG_OPACITY,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://cha
 kra-ui.com/guides/using-fonts
 */
const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: FONT_FAMILY.heading,
    subHeading: FONT_FAMILY.subHeading,
    body: FONT_FAMILY.body,
    body_lv2: FONT_FAMILY.body_lv2,
    name: FONT_FAMILY.name,
    and: FONT_FAMILY.and,
    fullName: FONT_FAMILY.fullName,

    // for generator purpose
    jost: `'Jost', sans-serif;`,
  },
  fontSizes: {
    heading: '32px',
    subHeading: '20px',
    body: '16px',
    body_lv2: '16px',
    name: '32px',
    and: '60px',
    fullName: '30px',
  },
  fontWeights: {
    heading: 400,
    subHeading: 400,
    body: 400,
    body_lv2: 400,
    name: 400,
    and: 300,
    fullName: 400,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-subHeading': '',
        '--fontItalic-body': '',
        '--fontItalic-body_lv2': '',
        '--fontItalic-name': '',
        '--fontItalic-and': '',
        '--fontItalic-fullName': '',
      },
    },
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgAlternative: BG_ALTERNATIVE,
    bgOpacity: BG_OPACITY,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    alternativeColorText: TEXT_ALTERNATIVE,
    transparent: 'transparent',
    theme: {
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
    },
  },
  // override components
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: 'normal',
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'subHeading',
      },
    },
  },
};

export default extendTheme(theme);
